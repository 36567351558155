import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Portrait = makeShortcode("Portrait");
const Age = makeShortcode("Age");
const Since = makeShortcode("Since");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Portrait mdxType="Portrait" />
    <h1>{`Who the Filip?`}</h1>
    <blockquote>
      <p parentName="blockquote">{`I'm `}<Age mdxType="Age" />{` years old digital product designer, and as of 2023, I'm also a first-time design manager. For the past `}<Since date="02/16/2019" mdxType="Since" />{`, I've been working at Staffbase, the leading employee communications platform. During this time, I've collaborated with cross-functional teams in both product discovery and delivery before transitioning to design management.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Prior to joining Staffbase, I was designing and building websites in a design agency while studying Media Computer Science at TU Dresden. During my studies, I explored interaction concepts for Augmented Reality (AR) and Virtual Reality (VR)."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Since 2012, I've been living in Dresden, Germany. Originally, I come from the Czech Republic.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Work Experience`}</h2>
    <h3>{`Staffbase`}</h3>
    <h4>{`Product Designer, Design Manager • Feb 2019-present`}</h4>
    <p>{`I joined Staffbase in early 2019 as one of the first product designers. Since then, I've worked on many different product areas in both discovery and delivery. In Q3 2023, I transitioned from an individual contributor to design management, aiming to multiply my impact within the orgnisation.`}</p>
    <h3>{`Narciss & Taurus`}</h3>
    <h4>{`Web Design & Development • Aug 2016-Feb 2019`}</h4>
    <p>{`Narciss & Taurus is a Dresden-based branding agency specializing in neuromarketing. As a working student, I built and launched nearly 10 websites while also being involved in conception and design. `}</p>
    <h3>{`AIESEC's Global Talent`}</h3>
    <h4>{`Internship • Jan-Mar 2016`}</h4>
    <p>{`After finishing my bachelor's degree, I participated in AIESEC's Global Talent program in Belo Horizonte, Brazil. At a local university startup incubator, I assisted startups with branding, web design, and mobile app design.`}</p>
    <h3>{`Queo`}</h3>
    <h4>{`Working Student • Aug 2014-Dec 2015`}</h4>
    <p>{`I joined queo, a full-service agency, as an intern in the design team and continued as a working student for several months. During my time there, I gained experience in branding, web design, and print design.`}</p>
    <hr></hr>
    <h2>{`Education`}</h2>
    <h3>{`M.Sc. Media Computer Science`}</h3>
    <h4>{`2016-2019 • Master's degree`}</h4>
    <p>{`In my Master's program, I specialized in interaction design, where I delved into AR/VR/XR technologies and conducted research to propose new interaction methods. For my Master's thesis, I designed and prototyped a mixed reality app for the Microsoft HoloLens.`}</p>
    <h3>{`B.Sc. Media Computer Science`}</h3>
    <h4>{`2012-2015 • Bachelor's degree`}</h4>
    <p>{`I learned the fundamentals of software development and interaction design. For my Bachelor's thesis, I designed and prototyped a touch interface for an agriculture 4.0 application.`}</p>
    <hr></hr>
    <h2>{`Speaking`}</h2>
    <h3>{`Product Design Meetup`}</h3>
    <h4>{`2019 • Meetup & Workshop`}</h4>
    <p>{`In the first product design meetup hosted at Staffbase, I gave a talk on an introduction to product design. We ran a small group exercise and had pizza.`}</p>
    <h3>{`UX/UI Meetup Dresden`}</h3>
    <h4>{`2019 • Meetup & Workshop`}</h4>
    <p>{`For the Dresden community focused on UX/UI design, I gave a talk about Design Sprints and how you can get started with them. We tried an exercise with the group, attempting to redesign Christmas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      